body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  backface-visibility: hidden;
}

.solar-term-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-position: center;
  background-size: cover;
  color: #f1f1f1;
  line-height: 24px;
  text-shadow: #000 0.1em 0.1em 0.1em;
  font-size: 16px;
  transition: 300ms;
  backface-visibility: hidden;
}

.solar-term-item .content {
  flex: 1;
}

.solar-term-item .name {
  font-size: 40px;
  margin-right: 16px;
}

.solar-term-item .desc {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 44px;
  /* transition: all 1000ms; */
}
.solar-term-item.active {
  position: relative;
  z-index: 1;
  transform: translateY(-3px);
  box-shadow: 0px 10px 12px rgb(0 0 0 / 60%);
}
.solar-term-item.active .desc {
  max-height: 300px;
}
